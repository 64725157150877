export default [
    //Start ECA
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: () =>
    //         import ('@/views/MSALLogin.vue'),
    //     meta: {
    //         layout: 'full',
    //     },
    // },
    // {
    //     path: '/',
    //     name: 'base',
    //     component: () =>
    //         import ('@/views/Base.vue'),
    //     meta: {
    //         pageTitle: '',
    //         requiresAuth: true,
    //     },
    // },
    {
        path: '/',
        name: 'home',
        component: () =>
            import ('@/views/Application/list.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    // {
    //   path: '/eca',
    //   name: 'eca',
    //   component: () => import('@/views/Application/list.vue'),
    //   meta: {
    //     pageTitle: '',
    //     requiresAuth: true,
    //     breadcrumb: [
    //       {
    //         text: 'Applications',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
        path: '/createapplication',
        name: 'createapplication',
        component: () =>
            import ('@/views/Application/CreateApplication.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
            // breadcrumb: [
            //   {
            //     text: 'Applications',
            //     active: true,
            //   },
            // ],
        },
    },
    {
        path: '/applicationdetails',
        name: 'applicationdetails',
        component: () =>
            import ('@/views/Application/AppDetails.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/appdetails/:id',
        name: 'appdetails',
        component: () =>
            import ('@/views/Application/EditApplication.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/editapplication/:id',
        name: 'editapplication',
        component: () =>
            import ('@/views/Application/EditApp.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-user',
        name: 'ecauser',
        component: () =>
            import ('@/views/EcaUser/UserList.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-createuser',
        name: 'eca-createuser',
        component: () =>
            import ('@/views/EcaUser/AddUser.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-editusers/:id',
        name: 'eca-editeusers',
        component: () =>
            import ('@/views/EcaUser/EditUser.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-cloud',
        name: 'ecacloud',
        component: () =>
            import ('@/views/EcaCloud/CloudList.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-createcloudprofile',
        name: 'eca-createcloudprofile',
        component: () =>
            import ('@/views/EcaCloud/AddCloudProfile.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-editcloudprofile/:id',
        name: 'eca-editcloudprofile',
        component: () =>
            import ('@/views/EcaCloud/EditCloudProfile.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/ecafirewall',
        name: 'ecafirewall',
        component: () =>
            import ('@/views/EcaFirewall/FirewallList.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-createfirewall',
        name: 'eca-createfirewall',
        component: () =>
            import ('@/views/EcaFirewall/AddFirewall.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-editfirewall/:id',
        name: 'eca-editfirewall',
        component: () =>
            import ('@/views/EcaFirewall/EditFirewall.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/ecaroles',
        name: 'ecaroles',
        component: () =>
            import ('@/views/EcaRole/RoleList.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-createrole',
        name: 'eca-createrole',
        component: () =>
            import ('@/views/EcaRole/RoleCreate.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-editrole/:id',
        name: 'eca-editrole',
        component: () =>
            import ('@/views/EcaRole/RoleEdit.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    {
        path: '/eca-log',
        name: 'ecalog',
        component: () =>
            import ('@/views/EcaLog/LogList.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
        },
    },
    // end 
    {
        path: '/eca/add',
        name: 'eca_add',
        component: () =>
            import ('@/views/eca/add.vue'),
        meta: {
            pageTitle: '',
            requiresAuth: true,
            breadcrumb: [{
                text: 'Add application',
                active: true,
            }, ],
        },
    },
    {
        path: '/eca/vulnerability/detail/:id',
        name: 'eca_detail',
        component: () =>
            import ('@/views/eca/detail.vue'),
        meta: {
            pageTitle: 'ECA',
            requiresAuth: true,
            breadcrumb: [{
                text: 'Detail',
                active: true,
            }, ],
        },
    },
    {
        path: '/eca/vulnerability/edit/:id',
        name: 'eca_edit',
        component: () =>
            import ('@/views/eca/edit.vue'),
        meta: {
            pageTitle: 'ECA',
            requiresAuth: true,
            breadcrumb: [{
                text: 'Edit',
                active: true,
            }, ],
        },
    },

]