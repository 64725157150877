import { $themeBreakpoints } from '@themeConfig'
import axios from 'axios'
// import router from '../../router'
// import store from '..';

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false,
        status: '',
        token: null,
        user: {
            id: "",
            username: "",
            groups: [],
            fullname: "",
            email: "",
            is_active: false,
            is_staff: false,
            first_name: "",
            last_name: "",
            // profile: {
            //     organization: null,
            //     is_organization_owner: false,
            //     o_profile: null
            // },
            permissions: []
        },
        isAdmin: false,
        userId: 0,
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
        },
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token) {
            console.log('Mutation calling')
            state.status = 'success'
            state.token = token
        },
        auth_error(state) {
            state.status = 'error'
        },
        auth_isAdmin(state) {
            state.isAdmin = true
        },
        set_userData(state, user) {
            state.user = user
            state.user.fullname = user.first_name + " " + user.last_name
        },
        set_userAttributes(state, permissions) {
            // console.log("permissions", permissions)
            state.user.permissions = permissions
        },
        logout(state) {
            state.status = ''
            state.token = null
                // localStorage.setItem('token', "null");
            state.isAdmin = false
            state.user = {
                id: "",
                username: "",
                groups: [],
                fullname: "",
                email: "",
                is_active: false,
                is_staff: false,
                first_name: "",
                last_name: "",
                // profile: {
                //     organization: null,
                //     is_organization_owner: false,
                //     o_profile: null
                // },
                permissions: []
            }
        },

    },
    actions: {
        login({ commit }, data) {

            // alert("logging in")
            return new Promise((resolve, reject) => {
                // const BASE_URL = process.env.VUE_APP_BASEURL
                // console.log(BASE_URL)
                commit('auth_request')
                    // axios({url: BASE_URL+'api-token-auth/', data: user, method: 'POST' })
                    // .then(resp => {
                if (data.token != "") {
                    const token = data.token
                    const user = data.userData
                        // const permission = data.userData.groups[0].permissions
                    const permission = data.permissions
                    // console.log(data, "new permission")
                    // console.log(token, "<<< token >>>")
                    // console.log(user, "<<< user >>>")
                        // console.log(permission, "<<< permissionssss >>>")
                        // localStorage.setItem('token', token)
                    localStorage.setItem('userid', user.id)
                    localStorage.setItem('loggedInTime', new Date())
                    localStorage.setItem('JWTtoken', token)
                        // axios.defaults.headers.common['Authorization'] = token
                    commit('auth_success', token)
                    commit('set_userData', user)
                    commit('set_userAttributes', permission)
                        // commit('set_userAttributes', data.userData.groups.permissions)
                    if (user.is_staff)
                        commit('auth_isAdmin')
                    resolve(true)
                } else {
                    commit('auth_error')
                    localStorage.removeItem('token')
                    reject(err)
                }
            })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                sessionStorage.clear()
                localStorage.removeItem('token') //if any
                localStorage.removeItem('loggedInTime')
                delete axios.defaults.headers.common['Authorization']
                resolve()
                    // this.$router.push({ name: 'login' })
            })
        },
        refresh({ commit }, token) {
            return new Promise((resolve, reject) => {
                let data = {
                    "token": token
                }
                const BASE_URL = process.env.VUE_APP_BASEURL
                axios({ url: BASE_URL + 'api-token-refresh/', data: data, method: 'POST' })
                    .then(resp => {
                        const token = resp.data.token
                        const user = resp.data.userData
                            // localStorage.setItem('token', token)
                        localStorage.setItem('userid', user.id)
                        localStorage.setItem('loggedInTime', new Date())
                        axios.defaults.headers.common['Authorization'] = token
                        commit('auth_success', token)
                        commit('set_userData', user)
                        commit('set_userAttributes', resp.data.permissions)
                        if (user.is_staff)
                            commit('auth_isAdmin')
                        resolve(resp)
                    })
            })
        }
    },
    getters: {
        currentBreakPoint: state => {
            const { windowWidth } = state
            if (windowWidth >= $themeBreakpoints.xl) return 'xl'
            if (windowWidth >= $themeBreakpoints.lg) return 'lg'
            if (windowWidth >= $themeBreakpoints.md) return 'md'
            if (windowWidth >= $themeBreakpoints.sm) return 'sm'
            return 'xs'
        },
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        isAdmin: state => state.isAdmin,
        token: state => state.token,
    },
}