import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import eca from './routes/eca'
// Vue.forceUpdate()
Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
     ...eca,

    // {
    //   path: '/task/',
    //   name: 'home',
    //   component: () => import('@/views/Task/index_as_list.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     contentRenderer: 'sidebar-left',
    //     contentClass: 'todo-application',
    //   }, 
    // },


    //User routes
    {
      path: '/users/',
      name: 'Users',
      component: () => import('@/views/User/index.vue'),
      meta: {
        pageTitle: 'Users',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/add',
      name: 'Add User',
      component: () => import('@/views/User/add.vue'),
      meta: {
        pageTitle: 'Add User',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Add User',
            active: true
          },
        ],
      },
    },
    {
      path: '/users/edit/:id',
      name: 'Edit User',
      component: () => import('@/views/User/edit.vue'),
      meta: {
        pageTitle: 'Edit User',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Edit User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/reset-password',
      name: 'Reset Password',
      component: () => import('@/views/User/reset_password.vue'),
      meta: {
        pageTitle: 'Reset Password',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Reset',
            active: true,
          },
        ],
      },
    },
    // Role routes//
    //User routes
    {
      path: '/roles/',
      name: 'Roles',
      component: () => import('@/views/Role/index.vue'),
      meta: {
        pageTitle: 'Roles',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles/add',
      name: 'Add Role',
      component: () => import('@/views/Role/add.vue'),
      meta: {
        pageTitle: 'Role',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Add',
            active: true
          },
        ],
      },
    },
    {
      path: '/roles/edit/:id',
      name: 'Edit Role',
      component: () => import('@/views/Role/edit.vue'),
      meta: {
        pageTitle: 'Role',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/MSALLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/signup',
      name: 'page-sign-up',
      component: () => import('@/views/Registration.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'forgot-password-form',
      component: () => import('@/views/forgot_password.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password/:token',
      name: 'forgot-password',
      component: () => import('@/views/reset_password.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/access-denied',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {

    var timeNow = new Date();
    var timeLoggedIn = new Date(localStorage.getItem('loggedInTime'))
    var minutes = ((timeNow.getTime() - timeLoggedIn.getTime()) / 1000)/60;
    console.log("time",Math.ceil(minutes))
    minutes = Math.ceil(minutes)
    // if(minutes >10 && minutes < 15)
    //     // alert("refredh")
    //     store.dispatch('app/refresh',store.state.app.token)

    // //console.log(store.getters)
    if (store.getters['app/isLoggedIn']) {
      console.log('logged in')
      next()
      return
    }
    console.log('not logged in')
    localStorage.setItem('from_url', to.path)
    next('/login')
  } else {
    next()
  }
})
export default router
